<template>
  <b-card
    v-if="paginatedContents"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="paginatedContents"
      responsive
      :fields="tableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >

      <!-- Column:Name -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              :id="`content-row-${data.item.id}`"
              size="32"
              :variant="mapToContentTypeIcon(data.item.contentTypeId).variant"
              :to="{ name: 'content-view', params: { id: data.item.id } }"
            >
              <feather-icon
                :icon="mapToContentTypeIcon(data.item.contentTypeId).icon"
              />
            </b-avatar>
          </template>
          <b-link
            :to="{ name: 'content-view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ strSubstring(data.item.name,20) }}
          </b-link>
          <small class="text-muted"> <b-badge
            pill
            :variant="`${mapFrequencyToVariant(data.item.contentFrequency.name)}`"
            class="text-capitalize small"
          >
            {{ data.item.contentType.name }}
          </b-badge>
          </small>
          <small class="text-muted"> <b-badge
            pill
            :variant="`${mapFrequencyToVariant(data.item.contentFrequency.name)}`"
            class="text-capitalize small"
          >
            {{ data.item.contentFrequency.name }}
          </b-badge>
          </small>
        </b-media>
      </template>
      <!-- Column:Reference ID -->
      <template #cell(reference_id)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ strSubstring(data.item.externalReference,10) }}
          </span>
        </b-media>
      </template>
      <!-- Column:Category -->
      <template #cell(category)="data">
        <div class="d-flex align-items-center">
          <!-- Avatar with Dynamic Variant Color -->
          <b-avatar
            class="mr-1"
            :variant="mapTagToVariant(data.item.tag).variant"
          >
            <!-- Feather Icon inside the Avatar -->
            <feather-icon
              size="18"
              :icon="mapTagToVariant(data.item.tag).Icon"
            />
          </b-avatar>
          <!-- Display Tag or Fallback to 'Other' -->
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.tag !== null ? data.item.tag : 'Other' }}
          </span>
        </div>
      </template>
      <!-- Column:StartDate -->
      <template #cell(start)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.start) }}
          </span>
        </b-media>
      </template>
      <!-- Column:EndDate -->
      <template #cell(end)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.end) }}
          </span>
        </b-media>
      </template>

      <!-- Column:Status -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`${mapStatusToVariant(data.item.contentStatus.name)}`"
          class="text-capitalize small"
        >
          {{ data.item.contentStatus.name }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            v-show="isTodayOrFutureDate(project.end) && (project.isAdmin)"
            :id="`content-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'content-view', params: { id: data.item.id }})"
          />
          <feather-icon
            v-show="isTodayOrFutureDate(project.end) && (project.isAdmin)"
            :id="`content-row-${data.item.id}-edit-icon`"
            icon="EditIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="$router.push({ name: 'content-configuration-id', params: { id: data.item.id }})"
          />
          <feather-icon
            v-show="isTodayOrFutureDate(project.end) && (project.isAdmin) && (data.item.contentStatusId===2)"
            :id="`content-row-${data.item.id}-mail-icon`"
            icon="MailIcon"
            size="16"
            class="cursor-pointer mr-1"
            @click="confirmAndEmailReminders(data.item)"
          />
          <!-- Dropdown -->
          <b-dropdown
            v-show="isTodayOrFutureDate(project.end) && (userData.userRoleId == userRole.ADMIN || userData.userRoleId == userRole.COMPANY_ADMIN)"
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-show="isTodayOrFutureDate(data.item.end) && data.item.contentTypeId === contentType.GAME"
              @click="sendEmailInvitation(data.item)"
            >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">{{ $t('INVITATION_EMAIL') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-show="isTodayOrFutureDate(data.item.end) && data.item.contentTypeId === contentType.GAME"
              @click="sendSmsInvitation(data.item)"
            >
              <feather-icon icon="MessageCircleIcon" />
              <span class="align-middle ml-50">{{ $t('INVITATION_SMS') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-show="isTodayOrPastDate(data.item.start)"
              @click="analyzeSurvey(data.item)"
            >
              <feather-icon icon="SlackIcon" />
              <span class="align-middle ml-50">{{ $t('AI_ANALYZE') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="confirmAndDeleteContent(data.item)">
              <feather-icon
                icon="Trash2Icon"
              />
              <span class="align-middle ml-50">{{ $t('BUTTON.DELETE') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="submitArchive(data.item)">
              <feather-icon icon="ArchiveIcon" />
              <span class="align-middle ml-50">
                {{ data.item.recordStatus > 0 ? $t('ARCHIVE') : $t('UNARCHIVE') }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalContents"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BAvatar,
  BLink,
  BMedia,
  BBadge,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mapActions, mapState } from 'vuex'
import { mixinDate } from '@/constants/mixinDate'
import { mixinList } from '@/constants/mixinValidations'
import constants from '@/constants/static.json'
import {
  mapTagToVariant,
  mapStatusToVariant,
  mapFrequencyToVariant,
  mapToContentTypeIcon,
  isTodayOrFutureDate,
  isTodayOrPastDate,
} from '@/constants/utils'
import useJwt from '@/auth/jwt/useJwt'
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BAvatar,
    BLink,
    BMedia,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  mixins: [mixinDate, mixinList],
  props: {
    project: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sortBy: 'id',
      perPage: constants.PER_PAGE,
      contentType: constants.CONTENT_TYPE,
      perPageOptions: constants.PER_PAGE_OPTIONS,
      currentPage: 1,
      tableColumns: [
        { key: 'name', label: 'Name', sortable: false },
        { key: 'reference_id', label: 'Reference ID', sortable: false },
        { key: 'category', label: 'Category', sortable: false },
        { key: 'start', label: 'Start Date', sortable: false },
        { key: 'end', label: 'End Date', sortable: false },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'Actions' },
      ],
      userData: useJwt.getUser(),
      userRole: constants.USER_ROLE,
    }
  },
  computed: {
    ...mapState('content', ['allContents']),
    totalContents() {
      return this.allContents.length
    },
    dataMeta() {
      const localItemsCount = this.allContents.length
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalContents,
      }
    },
    paginatedContents() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.allContents.slice(start, end)
    },
  },
  created() {
    if (this.project.id) {
      this.fetchContentsByProjectID(this.project.id)
    }
  },
  methods: {
    ...mapActions('content',
      ['deleteContent',
        'sendRemindersEmail',
        'fetchContentsByProjectID']),
    confirmAndDeleteContent(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.name}  ${this.$i18n.t('Contents')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.handleDeleteContent(data)
          }
        })
    },
    confirmAndEmailReminders(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.CONFIRM_SEND_REMINDER_EMAIL')} ${data.name}   ${this.$i18n.t('Contents')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.sendRemindersEmail(data.id).then(response => {
              if (response) {
                this.successMessage(response.data)
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    analyzeSurvey(data) {
      let message
      if (data.analyze) {
        message = `${this.$i18n.t('MESSAGE.RE_ANALYZE_CONFIRMATION')} ?`
      } else {
        message = `${this.$i18n.t('MESSAGE.ANALYZE_CONFIRMATION')} ${data.name}   ${this.$i18n.t('Contents')} ?`
      }
      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.warningMessage(this.$i18n.t('MESSAGE.ANALYZE_WAIT'))
            this.onAnalyzeSurvey(data)
          }
        })
    },
    sendSmsInvitation(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.INVITATION_SMS_CONFIRMATION')} ${data.name}   ${this.$i18n.t('Contents')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const payload = {
              contentId: data.id,
              userId: 0,
              temmId: 0,
            }
            this.sendSurveyInvitation(payload).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.PLAYER_INVITED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    sendEmailInvitation(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.INVITATION_EMAIL_CONFIRMATION')} ${data.name}   ${this.$i18n.t('Contents')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const payload = {
              contentId: data.id,
              userId: 0,
              isInviteAll: true,
            }
            this.sendContentInvitation(payload).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.PLAYER_INVITED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    onAnalyzeSurvey(data) {
      if (data) {
        this.createSurveyAnalyze(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.ANALYZE_CREATED'))
            // eslint-disable-next-line no-param-reassign
            data.analyze = true
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
    handleDeleteContent(data) {
      if (data) {
        this.deleteContent(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.CONTENT_DELETED'))
            this.fetchContentsByProjectID(this.project.id)
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    } = mixinAlert()

    return {
      mapTagToVariant,
      mapStatusToVariant,
      mapFrequencyToVariant,
      mapToContentTypeIcon,
      isTodayOrFutureDate,
      isTodayOrPastDate,
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    }
  },

}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
