<template>
  <b-modal
    id="new-player-modal"
    ref="new-player-modal"
    :title="modalTitle"
    cancel-variant="outline-secondary"
    @hidden="resetModal"
    @show="handleModalShow"
  >
    <!-- Search -->
    <b-row>
      <b-col
        cols="12"
      >
        <b-form-input
          v-model="searchQuery"
          :placeholder="$t('SEARCH')"
        />
      </b-col>
    </b-row>
    <!-- Users  -->
    <div
      v-for="(user,index) in filteredUsers"
      :key="user.id"
      class="d-flex justify-content-start align-items-center"
      :class="index == 0 ? 'mt-2' : 'mt-1'"
    >
      <feather-icon
        icon="UserIcon"
        size="24"
        class="mr-50 text-success"
      />
      <div class="user-page-info">
        <h6 class="mb-0">
          {{ user.firstName }} {{ user.lastName }}
        </h6>
        <small class="text-muted">{{ user.email }}</small>
      </div>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="text-success"
        class="btn-icon ml-auto"
        size="sm"
      >
        <feather-icon
          icon="UserPlusIcon"
          class="text-success"
          @click="handleSubmit(user)"
        />
      </b-button>
    </div>
    <!--/ Players  -->

  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BButton,
  BRow,
  BCol,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BModal, BButton, BRow, BCol, BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'selectedTeam',
  },
  props: {
    allTeamUsers: {
      type: Array,
      default: () => [],
    },
    project: {
      type: Object,
      required: true,
    },
    fetchTeams: {
      type: Function,
      required: true,
    },
    selectedTeam: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      users: [],
      filteredUsers: [],
      searchQuery: '',
    }
  },
  computed: {
    modalTitle() {
      const userCount = this.users ? this.users.length : 0
      const userText = userCount > 0
        ? `${userCount} ${this.$t('USERS_AVAILABLE')}`
        : this.$t('NO_USERS_AVAILABLE')
      return `${this.selectedTeam.name} - ${userText}`
    },
  },
  watch: {
    searchQuery: {
      handler() {
        if (this.searchQuery) {
          const queryLowered = this.searchQuery.toLowerCase()
          this.filteredUsers = this.users.filter(
            user => (user.firstName.toLowerCase().includes(queryLowered)
            || user.lastName.toLowerCase().includes(queryLowered)),
          )
        }
      },
    },
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
    }
  },
  methods: {
    ...mapActions('team', ['createTeamUser']),
    handleSubmit(data) {
      if (data.id) {
        this.createTeamUser({ userId: data.id, teamId: this.selectedTeam.id }).then(response => {
          if (response) {
            this.resetModal()
            this.fetchTeams(this.project.id)
            this.successMessage(this.$i18n.t('MESSAGE.USER_ADDED'))
          }
        })
      }
    },
    handleModalShow() {
      if (this.project && this.project.projectUsers) {
        this.users = this.project.projectUsers
          .filter(user => !this.allTeamUsers.some(teamUser => teamUser.userId === user.userId))
          .map(projectUser => ({
            id: projectUser.user.id,
            firstName: projectUser.user.firstName,
            lastName: projectUser.user.lastName,
            email: projectUser.user.email,
            role: projectUser.user.role,
            businessUnit: projectUser.user.businessUnit,
          }))
        this.filteredUsers = this.users.slice(0, 10)
      }
    },
    resetModal() {
      this.users = []
      this.filteredUsers = []
      this.searchQuery = ''
      this.$bvModal.hide('new-player-modal')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
