<template>
  <div>
    <b-card
      no-body
    >
      <div>
        <b-row>
          <b-col
            cols="4"
            md="4"
            class="d-flex align-items-center justify-content-start"
          >
            <b-button
              v-show="isTodayOrFutureDate(project.end) && (project.isAdmin)"
              variant="primary"
              @click="openTeamModel()"
            >
              <span>{{ $t('ADD_TEAM') }} </span>
              <feather-icon icon="PlusCircleIcon" />
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <b-row>
        <!-- No Teams Available Message -->
        <b-col
          v-if="allTeams.length === 0"
          cols="12"
        >
          <div class="text-left my-2">
            <p>{{ $t('MESSAGE.NO_TEAMS_AVAILABLE') }}</p>
          </div>
        </b-col>
        <!-- Teams Display -->
        <b-col
          v-for="(team,index) in allTeams"
          :key="index"
          lg="4"
          md="6"
        >
          <b-card class="card-app-design">
            <div class="design-group">
              <h6 class="section-label">
                {{ mapTeamRoleToVariant(team.teamCoach).description_2 }}
              </h6>
              <b-badge
                :key="index"
                :variant="changeVariant(index)"
                class="mr-1"
              >
                {{ team.name }}
              </b-badge>
              <feather-icon
                v-show="isTodayOrFutureDate(project.end) && (project.isAdmin)"
                icon="EditIcon"
                size="16"
                class="cursor-pointer  mr-1"
                @click="openTeamModel(team)"
              />
              <feather-icon
                v-show="isTodayOrFutureDate(project.end) && (project.isAdmin)"
                icon="Trash2Icon"
                size="16"
                class="cursor-pointer  mr-1"
                @click="handleTeamDelete(team)"
              />
            </div>
            <div class="design-group">
              <div>
                <h6 class="section-label">
                  {{ mapTeamRoleToVariant(team.teamCoach).description_1 }}
                  <feather-icon
                    v-show="isTodayOrFutureDate(project.end) && (project.isAdmin)"
                    icon="UserPlusIcon"
                    size="16"
                    class="cursor-pointer ml-1"
                    @click="openNewPlayerModel(team)"
                  />
                  <feather-icon
                    v-if="(team.teamUsers.length>0) && isTodayOrFutureDate(project.end) && (project.isAdmin)"
                    icon="UserMinusIcon"
                    size="16"
                    class="cursor-pointer ml-1"
                    @click="openPlayerModel(team)"
                  />
                </h6>
              </div>
            </div>
            <b-badge
              v-for="(teamUser,userIndex) in team.teamUsers"
              :key="userIndex"
              :variant="changeVariant(index)"
              class="mr-1"
            >
              {{ teamUser.user.firstName + ' ' + teamUser.user.lastName.charAt(0) }}
            </b-badge>
          </b-card>
        </b-col>
      </b-row>
      <team-model
        :fetch-teams="fetchTeams"
        :project="project"
        :selected-team="selectedTeam"
      />
      <player-model
        :fetch-teams="fetchTeams"
        :project="project"
        :selected-team="selectedTeam"
      />
      <new-player-model
        :all-team-users="allTeamUsers"
        :fetch-teams="fetchTeams"
        :project="project"
        :selected-team="selectedTeam"
      />
    </b-card>
    <b-card
      no-body
    ><team-view
      :all-team-users="allTeamUsers"
      :fetch-teams="fetchTeams"
      :project="project"
    />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  VBTooltip,
  BRow, BCol,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
// eslint-disable-next-line import/no-cycle
import constants from '@/constants/static.json'
import useJwt from '@/auth/jwt/useJwt'
import mixinAlert from '@/constants/mixinAlert'
import {
  mapTeamRoleToVariant,
  isTodayOrFutureDate,
} from '@/constants/utils'
import TeamModel from './TeamModel.vue'
import PlayerModel from '../team-configuration/PlayerModel.vue'
import NewPlayerModel from '../team-configuration/NewPlayerModel.vue'
import TeamView from '../team-view/TeamView.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    TeamModel,
    PlayerModel,
    NewPlayerModel,
    TeamView,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixinList, mixinDate],
  props: {
    project: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      allTeamUsers: [],
      userRole: constants.USER_ROLE,
      userData: useJwt.getUser(),
      emptyTeam: {
        id: 0,
        projectId: 0,
        name: '',
        description: '',
        colour: '',
        teamCoach: false,
      },
      selectedTeam: {},
    }
  },
  computed: {
    ...mapState('team', ['allTeams']),
  },
  created() {
    this.fetchTeams()
  },
  methods: {
    ...mapActions('team', [
      'deleteTeam',
      'fetchAllTeams']),
    // eslint-disable-next-line consistent-return
    async fetchTeams() {
      try {
        if (this.project) {
          await this.fetchAllTeams(this.project.id)
          this.transformTeamData()
        }
      } catch {
        this.showErrorMessage()
      }
    },
    openTeamModel(data) {
      if (data) {
        this.selectedTeam = { ...data }
      } else {
        this.selectedTeam = { ...this.emptyTeam }
      }
      this.$bvModal.show('team-modal')
    },
    openPlayerModel(data) {
      if (data) {
        this.selectedTeam = { ...data }
      }
      this.$bvModal.show('player-modal')
    },
    openNewPlayerModel(data) {
      if (data) {
        this.selectedTeam = { ...data }
      }
      this.$bvModal.show('new-player-modal')
    },
    handleTeamDelete(data) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.name}  team ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteTeam(data.id).then(response => {
              if (response) {
                this.fetchTeams()
                this.successMessage(this.$i18n.t('MESSAGE.TEAM_DELETED'))
              }
            }).catch(() => {
              this.errorMessage(this.$i18n.t('MESSAGE.TEAM_ATTACHED'))
            })
          }
        })
    },
    transformTeamData() {
      this.allTeamUsers = []
      this.allTeams.forEach(team => {
        team.teamUsers.forEach(teamUser => {
          this.allTeamUsers.push({
            teamId: teamUser.teamId,
            teamCoach: team.teamCoach,
            userId: teamUser.userId,
            teamName: team.name,
            firstName: teamUser.user.firstName,
            lastName: teamUser.user.lastName,
            email: teamUser.user.email,
          })
        })
      })
    },
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
      mapTeamRoleToVariant,
      isTodayOrFutureDate,
    }
  },

}
</script>
