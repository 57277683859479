<template>
  <b-card
    v-if="project.projectUsers.length>0"
    no-body
  >
    <p><span class="text-red">*** </span>{{ $t("MESSAGE.COLOR_INDICATION") }}</p>
    <b-table
      :items="paginatedProjectUsers"
      responsive
      :fields="tableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >

      <!-- Name -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              :id="`user-row-${data.item.id}`"
              size="32"
              variant="light-success"
              :to="{ name: 'user-profile-id', params: { id: data.item.user.id } }"
            >
              <feather-icon
                icon="UserIcon"
              />
            </b-avatar></template>
          <b-link
            :to="{ name: 'user-profile-id', params: { id: data.item.user.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.user.firstName }}  {{ data.item.user.lastName }}
          </b-link>
          <small class="text-muted">{{ data.item.user.email }}</small>
        </b-media>
      </template>
      <!-- Column: Project Role -->
      <template #cell(ProjectRole)="data">
        <div class="text-nowrap">
          <feather-icon
            :icon="resolveProjectRole(data.item.isProjectAdmin).Icon"
            size="18"
            class="mr-50"
            :class="`text-${resolveProjectRole(data.item.isProjectAdmin).variant}`"
          />
          <span class="align-text-top text-capitalize">{{ resolveProjectRole(data.item.isProjectAdmin).name }}</span>
        </div>
      </template>
      <!-- Column: Title -->
      <template #cell(Title)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.user.role }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- Business unit -->
      <template #cell(BusinessUnit)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.user.businessUnit }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- Last Login -->
      <template #cell(LastLogin)="data">
        <div class="d-flex align-items-left text-nowrap">
          <div>
            <small class="text-muted">{{ data.item.user.lastLogin ? formatDateTime(data.item.user.lastLogin) : $t('NEVER_LOGGED') }}</small>
          </div>
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`project-row-${data.item.userId}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="cursor-pointer mx-1"
            @click="$router.push({ name: 'user-profile-id', params: { id: data.item.userId }})"
          />
          <feather-icon
            v-show="isTodayOrFutureDate(project.end) && (project.isAdmin)"
            :id="`project-row-${data.item.userId}-edit-icon`"
            icon="EditIcon"
            size="16"
            class="cursor-pointer mr-1"
            @click="projectUserEdit(data.item)"
          />
          <feather-icon
            v-show="isTodayOrFutureDate(project.end) && (project.isAdmin)"
            :id="`project-row-${data.item.userId}-email-icon`"
            icon="MailIcon"
            size="16"
            :class="data.item.isSendEmail > 0 ? 'cursor-pointer mr-1 text-success' : 'cursor-pointer mr-1 text-warning'"
            @click="sendEmailInvitationToUser(data.item)"
          />
          <feather-icon
            v-show="isTodayOrFutureDate(project.end) && (project.isAdmin)"
            :id="`project-row-${data.item.userId}-delete-icon`"
            icon="Trash2Icon"
            size="16"
            class="cursor-pointer mr-1"
            @click="deleteProjectUser(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalProjectUsers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import {
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BAvatar,
  BLink,
  BMedia,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import {
  mapTeamRoleToVariant,
  resolveUserStatusVariant,
  resolveProjectRole,
  isTodayOrFutureDate,
} from '@/constants/utils'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { mixinDate } from '@/constants/mixinDate'
import constants from '@/constants/static.json'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BAvatar,
    BLink,
    BMedia,
  },
  mixins: [mixinDate],
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    deleteUser: {
      type: Function,
      required: true,
    },
    projectUserEdit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      sortBy: 'id',
      isSortDesc: true,
      perPage: constants.PER_PAGE,
      perPageOptions: constants.PER_PAGE_OPTIONS,
      currentPage: 1,
      tableColumns: [
        { key: 'Name' },
        { key: 'ProjectRole' },
        { key: 'Title' },
        { key: 'BusinessUnit' },
        { key: 'LastLogin' },
        { key: 'Actions' },
      ],
      userData: useJwt.getUser(),
      userRole: constants.USER_ROLE,
    }
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      isTodayOrFutureDate,
      resolveProjectRole,
      mapTeamRoleToVariant,
      successMessage,
      showErrorMessage,
      resolveUserStatusVariant,
    }
  },
  computed: {
    totalProjectUsers() {
      return this.project.projectUsers.length
    },
    dataMeta() {
      const localItemsCount = this.paginatedProjectUsers.length
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalProjectUsers,
      }
    },
    paginatedProjectUsers() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.project.projectUsers.slice(start, end)
    },
  },
  methods: {
    ...mapActions('project', ['deleteProjectUser', 'sendProjectInvitation']),
    deleteProjectUser(data) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.user.firstName}  ${data.user.lastName} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteUser(data)
          }
        })
    },
    sendEmailInvitationToUser(data) {
      if ((data.user.email)) {
        const message = data.isSendEmail
          ? this.$i18n.t('MESSAGE.RE_INVITATION_EMAIL_CONFIRMATION')
          : this.$i18n.t('MESSAGE.INVITATION_EMAIL_CONFIRMATION')
        this.$bvModal
          .msgBoxConfirm(`${message} to ${data.user.firstName}  ${data.user.lastName} ?`, {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              // eslint-disable-next-line no-param-reassign
              data.isSendEmail = true
              const payload = {
                projectId: data.projectId,
                userId: data.userId,
                isInviteAll: false,
              }
              this.sendProjectInvitation(payload).then(response => {
                if (response) {
                  this.successMessage(this.$i18n.t('MESSAGE.INVITATION_EMAIL_SEND'))
                }
              }).catch(() => {
                this.showErrorMessage()
              })
            }
          })
      } else {
        this.errorMessage(this.$i18n.t('MESSAGE.EMAIL_UNAVAILABLE'))
      }
    },
  },
}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.text-red {
  color: red;
}
</style>
