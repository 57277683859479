<template>
  <b-modal
    id="project-user-modal"
    ref="project-user-modal"
    :title="$t('ADD_PROJECT_PARTICIPANT')"
    :ok-title="projectUser.id ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD')"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <validation-observer ref="validationRules">
      <b-form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="$t('EMAIL_ADDRESS')"
              label-for="id-email"
              :invalid-feedback="$t('DESCRIPTION_REQUIRED')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('EMAIL_ADDRESS')"
                rules="required"
              >
                <b-form-input
                  id="id-email"
                  v-model="projectUser.email"
                  maxlength="60"
                  :placeholder="$t('EMAIL_ADDRESS')"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- First Name -->
          <b-col md="6">
            <b-form-group
              :label="$t('FIRST_NAME')"
              label-for="id-firstname"
            >
              <b-form-input
                id="id-firstname"
                v-model="projectUser.firstName"
                maxlength="45"
                :placeholder="$t('FIRST_NAME')"
              />
            </b-form-group>
          </b-col>
          <!-- Last Name -->
          <b-col md="6">
            <b-form-group
              :label="$t('LAST_NAME')"
              label-for="id-lastname"
            >

              <b-form-input
                id="id-lastname"
                v-model="projectUser.lastName"
                maxlength="45"
                :placeholder="$t('LAST_NAME')"
              />

            </b-form-group>
          </b-col>
          <!-- Business Unit-->
          <b-col md="6">
            <b-form-group
              :label="$t('BUSINESS_UNIT')"
              label-for="id-business-unit"
            >
              <b-form-input
                id="id-business-unit"
                v-model="projectUser.businessUnit"
                maxlength="50"
                :placeholder="$t('BUSINESS_UNIT')"
              />
            </b-form-group>
          </b-col>
          <!-- Project Role -->
          <b-col md="6">
            <b-form-group
              :label="$t('ROLE')"
              label-for="id-role"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('ROLE')"
                rules="required"
              >
                <b-form-input
                  id="id-role"
                  v-model="projectUser.role"
                  maxlength="45"
                  :placeholder="$t('ROLE')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Phone Number -->
          <b-col md="4">
            <b-form-group
              :label="$t('COUNTRY_CODE') + ' (+46)'"
              label-for="id-countrycode"
            >
              <!-- Country Code Input -->
              <b-form-input
                id="id-countrycode"
                v-model="projectUser.countryCode"
                :placeholder="$t('COUNTRY_CODE')"
                maxlength="5"
              />
            </b-form-group>
          </b-col>
          <b-col md="8">
            <b-form-group
              :label="$t('PHONE_NUMBER') + ' (70 123 45 67)'"
              label-for="id-phonenumber"
            >
              <b-form-input
                id="id-phonenumber"
                v-model="projectUser.phoneNumber"
                :placeholder="$t('PHONE_NUMBER')"
                maxlength="10"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <b-form-checkbox
                id="checkbox-is_project_admin"
                v-model="projectUser.isProjectAdmin"
                name="checkbox-is_project_admin"
              >
                {{ $t('PROJECT_ADMIN') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal,
  VBModal, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import parsePhoneNumberFromString from 'libphonenumber-js'
import { mapActions } from 'vuex'
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    parsePhoneNumberFromString,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'editProjectUser',
  },
  props: {
    editProjectUser: {
      type: Object,
      default: () => {},
    },
    company: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    projectUserSave: {
      type: Function,
      required: true,
    },
    projectUserEdit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      projectUser: {
        id: 0,
        projectId: 0,
        userId: '',
        role: '',
        userRoleId: 0,
        isProjectAdmin: false,
        firstName: '',
        lastName: '',
        email: '',
        companyId: 0,
        phoneNumber: '',
        businessUnit: '',
        countryCode: constants.DEFULT_COUNTRY_CODE.DIALING_CODE,
      },
      required,
      userRole: constants.USER_ROLE,
    }
  },
  watch: {
    company: {
      handler() {
        if (this.company) {
          this.projectUser.businessUnit = this.company.name
        }
      },
    },
    editProjectUser: {
      handler() {
        if (this.editProjectUser) {
          this.projectUser = { ...this.editProjectUser }
          this.projectUser.firstName = this.editProjectUser.user.firstName
          this.projectUser.lastName = this.editProjectUser.user.lastName
          this.projectUser.email = this.editProjectUser.user.email
          this.projectUser.countryCode = this.editProjectUser.user.countryCode
          this.projectUser.phoneNumber = this.editProjectUser.user.phoneNumber
          this.projectUser.businessUnit = this.editProjectUser.user.businessUnit
          this.projectUser.userRoleId = this.editProjectUser.user.userRoleId
          this.projectUser.isProjectAdmin = !!this.editProjectUser.isProjectAdmin
          this.projectUser.role = this.editProjectUser.user.role
          delete this.projectUser.user
        }
      },
    },
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
    }
  },
  methods: {
    ...mapActions('user', ['createUser', 'updateUser', 'fetchUserByEmail']),
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    resetModal() {
      this.projectUser = {
        id: 0,
        projectId: 0,
        userId: '',
        role: '',
        isProjectAdmin: false,
        firstName: '',
        lastName: '',
        email: '',
        companyId: 0,
        countryCode: '',
        phoneNumber: '',
        businessUnit: '',
      }
    },
    handleSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.projectUser.companyId = this.company.id
          this.projectUser.id = this.projectUser.userId
          if (this.projectUser.userId) {
            this.updateUser(this.projectUser).then(response => {
              if (response) {
                this.projectUserEdit(this.projectUser)
                this.successMessage(this.$i18n.t('MESSAGE.USER_UPDATED'))
                this.$bvModal.hide('project-user-modal')
                this.resetModal()
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          } else {
            this.fetchUserByEmail(this.projectUser.email).then(response => {
              if (response) {
                if (response.emailExists) {
                  this.errorMessage(this.$i18n.t('ALREADY_EXIST_EMAIL'))
                } else {
                  const userPayload = {
                    firstName: this.projectUser.firstName,
                    lastName: this.projectUser.lastName,
                    email: this.projectUser.email,
                    companyId: this.projectUser.companyId,
                    businessUnit: this.projectUser.businessUnit,
                    userRoleId: this.userRole.USER,
                    role: this.projectUser.role,
                    countryCode: this.projectUser.countryCode,
                    phoneNumber: this.projectUser.phoneNumber,
                  }
                  this.createUser(userPayload).then(result => {
                    if (result) {
                      const payload = {
                        id: result.data.id,
                        projectId: this.projectUser.projectId,
                        isProjectAdmin: this.projectUser.isProjectAdmin,
                      }
                      this.projectUserSave(payload)
                      this.$bvModal.hide('project-user-modal')
                      this.resetModal()
                      this.successMessage(this.$i18n.t('MESSAGE.USER_CREATED'))
                    }
                  }).catch(() => {
                    this.showErrorMessage()
                  })
                }
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~vue-phone-number-input/dist/vue-phone-number-input.css';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
