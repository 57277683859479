<template>
  <b-card
    v-if="paginatedUsers.length>0"
    no-body
  >
    <b-table
      :items="paginatedUsers"
      responsive
      :fields="tableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >

      <!-- Column: Name -->
      <template #cell(Name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              :id="`user-row-${data.item.userId}`"
              size="32"
              variant="light-success"
              :to="{ name: 'user-profile-id', params: { id: data.item.userId } }"
            >
              <feather-icon
                icon="UserIcon"
              />
            </b-avatar></template>
          <b-link
            :to="{ name: 'user-profile-id', params: { id: data.item.userId } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.firstName }}  {{ data.item.lastName }}
          </b-link>
          <small class="text-muted">{{ data.item.email }}</small>
        </b-media>
      </template>

      <!-- Column: Team -->
      <template #cell(team)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.teamName }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- Column: Last Login -->
      <template #cell(LastLogin)="data">
        <div class="d-flex align-items-left text-nowrap">
          <small class="text-muted">{{ data.item.lastLogin ? formatDateTime(data.item.lastLogin) : $t('NEVER_LOGGED') }}</small>
        </div>
      </template>

      <!-- Column: Role -->
      <template #cell(role)="data">
        <div class="text-nowrap">
          <feather-icon
            :icon="mapTeamRoleToVariant(data.item.teamCoach).Icon"
            size="18"
            class="mr-50"
            :class="`text-${mapTeamRoleToVariant(data.item.teamCoach).variant}`"
          />
          <span class="align-text-top text-capitalize">{{ mapTeamRoleToVariant(data.item.teamCoach).name }}</span>
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`user-eye-row-${data.item.userId}-view-icon`"
            icon="EyeIcon"
            size="16"
            class="cursor-pointer mx-1"
            @click="$router.push({ name: 'user-profile-id', params: { id: data.item.userId }})"
          />
          <feather-icon
            v-show="isTodayOrFutureDate(project.end) && (project.isAdmin)"
            :id="`user-delete-row-${data.item.userId}-delete-icon`"
            icon="Trash2Icon"
            size="16"
            class="cursor-pointer mr-1"
            @click="handleDeleteUser(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalUsers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import {
  BCard,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BPagination,
  BRow,
  BCol,
} from 'bootstrap-vue'

import { mapActions } from 'vuex'
// eslint-disable-next-line import/named
import { mapTeamRoleToVariant, resolveUserStatusVariant, isTodayOrFutureDate } from '@/constants/utils'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { mixinDate } from '@/constants/mixinDate'
import constants from '@/constants/static.json'

export default {
  components: {
    BCard,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BRow,
    BCol,
  },
  mixins: [mixinDate],
  props: {
    allTeamUsers: {
      type: Array,
      default: () => [],
    },
    fetchTeams: {
      type: Function,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sortBy: 'id',
      perPage: constants.PER_PAGE,
      perPageOptions: constants.PER_PAGE_OPTIONS,
      currentPage: 1,
      tableColumns: [
        { key: 'Name', sortable: false },
        { key: 'Team', sortable: false },
        { key: 'LastLogin', sortable: false },
        { key: 'Role' },
        { key: 'Actions', sortable: false },
      ],
    }
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      mapTeamRoleToVariant,
      successMessage,
      showErrorMessage,
      resolveUserStatusVariant,
      isTodayOrFutureDate,
    }
  },
  computed: {
    totalUsers() {
      return this.allTeamUsers.length
    },
    dataMeta() {
      const localItemsCount = this.allTeamUsers.length
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalUsers,
      }
    },
    paginatedUsers() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.allTeamUsers.slice(start, end)
    },
  },
  methods: {
    ...mapActions('team', [
      'deleteTeamUser',
      'updateTeamUser',
      'sendContentInvitation']),
    ...mapActions('content', [
      'sendContentInvitation']),
    handleActivateUser(data) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.ACTIVATED_CONFIRMATION')} ${data.firstName}  ${data.lastName} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            // eslint-disable-next-line no-param-reassign
            this.updateTeamUser(data.group_user).then(response => {
              if (response) {
                this.fetchTeams(this.project.id)
                this.successMessage(this.$i18n.t('MESSAGE.PLAYER_ACTIVATED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    handleDeleteUser(data) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.firstName}  ${data.lastName} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteTeamUser(data).then(response => {
              if (response) {
                this.fetchTeams(this.project.id)
                this.successMessage(this.$i18n.t('MESSAGE.PLAYER_DELETED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
  },
}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
</style>
